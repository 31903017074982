import React, { useContext, useState } from "react";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import { Input } from "@material-ui/core";
import LogoutIcon from "@mui/icons-material/Logout";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SettingsIcon from "@material-ui/icons/Settings";
import { AuthContext } from "../context/authContext";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

export default function SideBar({ open, handleDrawerClose }) {
  const classes = useStyles();
  const { user, addDash, editDash, changeDash, logout } =
    useContext(AuthContext);
  console.log(user);
  const [enabled, setEnabled] = useState(null);
  const handleLogout = () => {
    logout();
  };
  const addNew = () => {
    console.log("adding");
    addDash();
  };
  const switchDash = (index) => {
    console.log(index);
    changeDash(index);
  };

  const renameItem = (event) => {
    const newLayoutLbl = event.target.value;

    editDash(event.target.name, { label: newLayoutLbl });
  };

  const enableItem = (event) => {
    setEnabled(!enabled);
  };
  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader} style={{ marginTop: "80px" }}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <div className="">
        <List>
          {user.layouts &&
            user.layouts.map((layout, index) => {
              console.log(layout);
              return (
                <ListItem
                  button
                  key={index}
                  onClick={() => {
                    switchDash(index);
                  }}
                  name={index}
                >
                  <ListItemIcon>
                    <DashboardIcon />
                  </ListItemIcon>
                  <div onDoubleClick={enableItem}>
                    {enabled ? (
                      <Input
                        style={{ color: "black" }}
                        value={layout.label}
                        key={index}
                        name={index}
                        onChange={renameItem}
                      />
                    ) : (
                      <ListItemText secondary={layout.label} name={index} />
                    )}
                  </div>
                </ListItem>
              );
            })}
          <ListItem button onClick={addNew} style={{ width: "100%" }}>
            <ListItemIcon>
              <AddCircleOutlineIcon />
            </ListItemIcon>
            <ListItemText secondary="Add New" />
          </ListItem>
        </List>
        <Divider />
        <List>
          <ListItem button>
            <ListItemIcon>
              <SettingsIcon />
            </ListItemIcon>
            <ListItemText secondary="Settings" />
          </ListItem>
          <ListItem button onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText secondary="Logout" />
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
}
