import { Button } from "@mui/material";
import { darkPrimaryButton } from "../themes/dark/primarybutton";
import { lightPrimaryButton } from "../themes/light/primaryButton";
import UnreadMail from "../icons/UnreadMail.svg";
import ReadMail from "../icons/ReadEmail.svg";
export const PrimaryButton = ({ darkMode, onSubmit, text, ...props }) => {
  return (
    <Button
      style={darkMode ? darkPrimaryButton : lightPrimaryButton}
      fullWidth
      sx={{ mt: 3, mb: 2 }}
      onClick={onSubmit}
    >
      {text}
    </Button>
  );
};

export const ActionButton = ({
  darkMode,
  onSubmit,
  icon,
  disabled,
  ...props
}) => {
  console.log(icon);
  return (
    <Button
      sx={{ height: "50px", width: "50px" }}
      onClick={onSubmit}
      disabled={disabled}
    >
      <img
        src={icon === "unread" ? UnreadMail : ReadMail}
        style={{ height: "100%" }}
      />
    </Button>
  );
};
