import { useContext, useState } from "react";
import { AuthContext } from "../context/authContext";
import { useForm } from "../utility/hooks";
import { useMutation } from "@apollo/client";
import { gql } from "graphql-tag";
import veritasLogo from "../components/assets/images/veritas-logo.png";
import { useNavigate } from "react-router-dom";
import bcrypt from "bcryptjs";
import Link from "@mui/material/Link";
import { TextField, Alert } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import veritasLanding from "../components/assets/images/landing-page-image.png";
import { PrimaryButton } from "../components/buttons";
import { lightTitle } from "../themes/light/title";
import { darkTitle } from "../themes/dark/title";
import { lightTextInput } from "../themes/light/inputs";
import { darkTextInput } from "../themes/dark/inputs";
const LOGIN_USER = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
    }
  }
`;

const ADD_USER = gql`
  mutation ADDUSER($email: String!, $password: String!) {
    addWebUser(email: $email, password: $password) {
      token
    }
  }
`;

function Copyright({ site, ...props }) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        {site}
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function Login({ darkMode, site, classes, ...props }) {
  const context = useContext(AuthContext);
  console.log(context);
  let navigate = useNavigate();
  const [errors, setErrors] = useState([]);
  const [msgs, setMsgs] = useState([]);
  function loginUserCallback() {
    if (window.location.href.includes("sign-up")) {
      if (values.password !== values.confirmpassword) {
        setErrors([{ message: "Passwords do not match!" }]);
        return;
      }
      addUser();
    } else {
      loginUser();
    }
  }
  const { onChange, onSubmit, values } = useForm(loginUserCallback, {
    email: "",
    password: "",
  });

  const [loginUser] = useMutation(LOGIN_USER, {
    update(proxy, { data: { login: userData } }) {
      console.log(userData);
      context.login(userData);
      console.log(userData);
      navigate("/");
    },
    onError({ graphQLErrors }) {
      setErrors(graphQLErrors);
    },
    variables: {
      email: values.email,
      password: bcrypt.hashSync(values.password, 10),
    },
  });

  const [addUser] = useMutation(ADD_USER, {
    update(proxy, { data: { addWebUser: userData } }) {
      onChange({ target: { name: "password", value: "" } });
      onChange({ target: { name: "email", value: "" } });
      setMsgs(["Verification Email Sent!"]);
    },
    onError({ graphQLErrors }) {
      setErrors(graphQLErrors);
    },
    variables: {
      email: values.email,
      password: bcrypt.hashSync(values.password, 10),
    },
  });

  return (
    <Grid container style={{ height: "100vh", overflowY: "hidden" }}>
      <Box item xs={0} sm={0} md={6} lg={6} component={Grid}>
        <div
          style={{
            height: "100vh",
            overflowY: "hidden",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {site.includes("novomo") && (
            <img
              alt="veritas-detailed-logo"
              src={veritasLanding}
              width={"100%"}
              height={"auto"}
              margin={0}
            />
          )}
          {site.includes("localhost") && (
            <img
              alt="veritas-detailed-logo"
              src={veritasLanding}
              width={"auto"}
              height={"100%"}
              margin={0}
            />
          )}
        </div>
      </Box>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Box
          sx={{
            height: "100vh",
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundImage: { xs: "none" },
          }}
        >
          <Box
            sx={{
              height: "25%",
              mx: 4,
              gap: 2,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {site.includes("novomo") && (
              <img
                alt="veritas-logo"
                src={veritasLogo}
                width={100}
                height={100}
              />
            )}
            {site.includes("localhost") && (
              <img
                alt="veritas-logo"
                src={veritasLogo}
                width={100}
                height={100}
              />
            )}
            <Typography
              component="h1"
              variant="h3"
              marginTop={7}
              style={darkMode ? darkTitle : lightTitle}
            >
              Veritas
            </Typography>
          </Box>
          <Box
            sx={{
              height: "75%",
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography component="h1" variant="h5">
              Sign {!window.location.href.includes("sign-up") ? "in" : "up"}
            </Typography>
            <Box
              component="form"
              width={"400px"}
              noValidate
              sx={{ mt: 1 }}
              height={"80%"}
            >
              <TextField
                style={darkMode ? darkTextInput : lightTextInput}
                margin="normal"
                required
                fullWidth
                label="email"
                id="email"
                name="email"
                onChange={onChange}
                autoComplete="email"
                autoFocus
              />
              <TextField
                style={darkMode ? darkTextInput : lightTextInput}
                margin="normal"
                required
                fullWidth
                label="password"
                name="password"
                type="password"
                onChange={onChange}
                id="password"
                autoComplete="current-password"
              />

              {window.location.href.includes("sign-up") && (
                <TextField
                  style={darkMode ? darkTextInput : lightTextInput}
                  margin="normal"
                  required
                  fullWidth
                  label="confirmpassword"
                  name="confirmpassword"
                  type="password"
                  onChange={onChange}
                  id="confirmpassword"
                  autoComplete="current-password"
                />
              )}
              {errors.map(function (error) {
                return <Alert severity="error">{error.message}</Alert>;
              })}
              {msgs.map(function (msg) {
                return <Alert severity="info">{msg}</Alert>;
              })}
              <PrimaryButton
                darkMode={darkMode}
                onSubmit={onSubmit}
                text={
                  window.location.href.includes("sign-up") ? "Sign Up" : "Login"
                }
              ></PrimaryButton>
              <Grid container>
                <Grid item xs>
                  {!window.location.href.includes("sign-up") && (
                    <Link href="/reset-password" variant="body2">
                      Forgot password?
                    </Link>
                  )}
                </Grid>
                <Grid item>
                  {!window.location.href.includes("sign-up") ? (
                    <Link href="/sign-up" variant="body2">
                      {"Don't have an account? Sign Up"}
                    </Link>
                  ) : (
                    <Link href="/login" variant="body2">
                      {"Already have an account? Sign In"}
                    </Link>
                  )}
                </Grid>
              </Grid>
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default Login;
