import "./App.css";
import { onMessage } from "firebase/messaging";
import { messaging } from "./firebase/firebaseConfig";
import { toast, ToastContainer } from "react-toastify";
import { setupNotifications } from "./firebase/firebaseConfig";

import { useVisibilityChange } from "./utility/hooks";
import "react-toastify/dist/ReactToastify.css";
import { Routes, Route } from "react-router-dom";
import { AuthContext } from "./context/authContext";
import HomePage from "./pages/homepage";
import Login from "./pages/login";
import EmailVerification from "./components/EmailVerification";
import Navbar from "./components/Navbar";
import ResetPass from "./pages/resetPass";
import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { CssBaseline, ThemeProvider, createTheme } from "@material-ui/core";
//import Content from "./Content";
//import Sidebar from "./Sidebar";
import "./styles.css";

import { lightTheme } from "./themes/light/light";
import { darkTheme } from "./themes/dark/dark";
import BasicSnackbar from "./components/Snackbar";
import SideBar from "./components/SideBar";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
}));

function App() {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const [open, setOpen] = useState(true);
  const [darkMode, setDarkMode] = useState(true);
  const isForeground = useVisibilityChange();
  const getDesignTokens = () => ({
    palette: {
      darkMode,
      ...(darkMode ? darkTheme : lightTheme),
    },
  });
  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const theme = createTheme(getDesignTokens(darkMode));
  onMessage(messaging, (payload) => {
    toast(<BasicSnackbar notification={payload.notification} />);
  });

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  useEffect(() => {
    setupNotifications((message) => {
      if (isForeground) {
        // App is in the foreground, show toast notification
        toast(<BasicSnackbar notification={message.notification} />);
      }
    });
  }, [user]);

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        {user && (
          <>
            <Navbar
              handleDrawerToggle={handleDrawerToggle}
              toggleDarkMode={toggleDarkMode}
              darkMode={darkMode}
              site={window.location.host}
            />
            <SideBar handleDrawerClose={handleDrawerClose} open={open} />
          </>
        )}
        <Routes>
          <Route
            path="/verifyuseremail/:username/:token"
            element={<EmailVerification />}
          />
          <Route path="/" element={<HomePage />} />
          <Route
            path="/reset-password"
            element={<ResetPass site={window.location.host} />}
          />
          <Route
            path="/login"
            element={
              <Login
                site={window.location.host}
                classes={classes}
                darkMode={darkMode}
              />
            }
          />
          <Route
            path="/sign-up"
            element={
              <Login
                site={window.location.host}
                classes={classes}
                darkMode={darkMode}
              />
            }
          />
        </Routes>
        <ToastContainer />
      </div>
    </ThemeProvider>
  );
}

export default App;
