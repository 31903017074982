import React from "react";
import { useState } from "react";
import { useContext } from "react";
import { RecoveryContext } from "../pages/resetPass";
import Link from "@mui/material/Link";
import { gql } from "graphql-tag";
import { TextField, Alert, Grid } from "@mui/material";
import { Box } from "@mui/material";
import { useMutation } from "@apollo/client";
import Typography from "@mui/material/Typography";
import { PrimaryButton } from "../components/buttons";
import veritasLogo from "../components/assets/images/veritas-logo.png";
import { lightTitle } from "../themes/light/title";
import { darkTitle } from "../themes/dark/title";
import { lightTextInput } from "../themes/light/inputs";
import { darkTextInput } from "../themes/dark/inputs";
const SEND_EMAIL = gql`
  mutation ForgottenPassword($email: String!, $OTP: String!) {
    forgottenPassword(email: $email, OTP: $OTP) {
      token
    }
  }
`;

export default function OTPInput({ email, site, ...props }) {
  let darkMode = true;
  const { otp, setPage } = useContext(RecoveryContext);
  const [timerCount, setTimer] = React.useState(60);
  const [OTPinput, setOTPinput] = useState([0, 0, 0, 0]);
  const [disable, setDisable] = useState(true);
  const [errors, setErrors] = useState([]);
  const [msgs, setMsgs] = useState([]);
  const [forgotPass] = useMutation(SEND_EMAIL, {
    update(proxy, { data: { forgottenPassword } }) {
      setPage("otp");
    },
    onError({ graphQLErrors }) {
      setErrors(graphQLErrors);
    },
    variables: {
      email: email,
      otp: otp,
    },
  });
  function resendOTP() {
    if (disable) return;
    forgotPass();
  }

  function verfiyOTP() {
    console.log(OTPinput.join(""));
    if (OTPinput.join("") === otp) {
      setPage("reset");
      return;
    }
    alert(
      "The code you have entered is not correct, try again or re-send the link"
    );
    return;
  }

  React.useEffect(() => {
    let interval = setInterval(() => {
      setTimer((lastTimerCount) => {
        lastTimerCount <= 1 && clearInterval(interval);
        if (lastTimerCount <= 1) setDisable(false);
        if (lastTimerCount <= 0) return lastTimerCount;
        return lastTimerCount - 1;
      });
    }, 1000); //each count lasts for a second
    //cleanup the interval on complete
    return () => clearInterval(interval);
  }, [disable]);

  return (
    <div>
      <Box
        container
        style={{
          height: "100vh",
          overflow: "hidden",
          width: "100vw",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Grid item xs={12} sm={12} md={6} lg={6} fullWidth>
          <Box
            sx={{
              height: "100vh",
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              backgroundImage: { xs: "none" },
            }}
          >
            <Box
              sx={{
                height: "25%",
                mx: 4,
                gap: 2,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Link href="/">
                {site.includes("novomo") && (
                  <img
                    alt="veritas-logo"
                    src={veritasLogo}
                    width={100}
                    height={100}
                  />
                )}
                {site.includes("localhost") && (
                  <img
                    alt="veritas-logo"
                    src={veritasLogo}
                    width={100}
                    height={100}
                  />
                )}
              </Link>
              <Typography
                component="h1"
                variant="h3"
                marginTop={7}
                style={darkMode ? darkTitle : lightTitle}
              >
                OTP Verification
              </Typography>
            </Box>

            <Box
              sx={{
                height: "75%",
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                component="p"
                marginTop={7}
                style={darkMode ? darkTitle : lightTitle}
              >
                We have sent a code to your email {email}
              </Typography>
              <Box
                component="form"
                width={"300px"}
                noValidate
                sx={{ mt: 1 }}
                height={"80%"}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    paddingY: "16px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      marginX: "auto",
                    }}
                  >
                    <Box sx={{ width: "64px", height: "64px" }}>
                      <TextField
                        maxLength="1"
                        sx={{
                          height: "64px",
                          width: "64px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          paddingX: "5px",
                          outline: "none",
                          backgroundColor: "white",
                          borderRadius: "5px",
                          border: "gray",
                        }}
                        type="text"
                        name=""
                        id=""
                        onChange={(e) =>
                          setOTPinput([
                            e.target.value,
                            OTPinput[1],
                            OTPinput[2],
                            OTPinput[3],
                          ])
                        }
                      ></TextField>
                    </Box>
                    <Box sx={{ width: "64px", height: "64px" }}>
                      <TextField
                        maxLength="1"
                        sx={{
                          height: "64px",
                          width: "64px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          paddingX: "5px",
                          outline: "none",
                          backgroundColor: "white",
                          borderRadius: "5px",
                          border: "gray",
                        }}
                        type="text"
                        name=""
                        id=""
                        onChange={(e) =>
                          setOTPinput([
                            OTPinput[0],
                            e.target.value,
                            OTPinput[2],
                            OTPinput[3],
                          ])
                        }
                      ></TextField>
                    </Box>
                    <Box sx={{ width: "64px", height: "64px" }}>
                      <TextField
                        maxLength="1"
                        sx={{
                          height: "64px",
                          width: "64px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          paddingX: "5px",
                          outline: "none",
                          backgroundColor: "white",
                          borderRadius: "5px",
                          border: "gray",
                        }}
                        type="text"
                        name=""
                        id=""
                        onChange={(e) =>
                          setOTPinput([
                            OTPinput[0],
                            OTPinput[1],
                            e.target.value,
                            OTPinput[3],
                          ])
                        }
                      ></TextField>
                    </Box>
                    <Box sx={{ width: "64px", height: "64px" }}>
                      <TextField
                        maxLength="1"
                        sx={{
                          height: "64px",
                          width: "64px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          textAlign: "center",
                          paddingX: "5px",
                          outline: "none",
                          backgroundColor: "white",
                          borderRadius: "5px",
                          border: "gray",
                        }}
                        type="text"
                        name=""
                        id=""
                        onChange={(e) =>
                          setOTPinput([
                            OTPinput[0],
                            OTPinput[1],
                            OTPinput[2],
                            e.target.value,
                          ])
                        }
                      ></TextField>
                    </Box>
                  </Box>
                  <div className="flex flex-col space-y-5">
                    <div>
                      <PrimaryButton
                        darkMode={darkMode}
                        onSubmit={() => verfiyOTP()}
                        className="flex flex-row cursor-pointer items-center justify-center text-center w-full border rounded-xl outline-none py-5 bg-blue-700 border-none text-white text-sm shadow-sm"
                        text={"Verify Account"}
                      ></PrimaryButton>
                    </div>

                    <div className="flex flex-row items-center justify-center text-center text-sm font-medium space-x-1 text-gray-500">
                      <p>Didn't recieve code?</p>{" "}
                      <a
                        className="flex flex-row items-center"
                        style={{
                          color: disable ? "gray" : "blue",
                          cursor: disable ? "none" : "pointer",
                          textDecorationLine: disable ? "none" : "underline",
                        }}
                        onClick={() => resendOTP()}
                      >
                        {disable
                          ? `Resend OTP in ${timerCount}s`
                          : "Resend OTP"}
                      </a>
                    </div>
                    {errors.map(function (error) {
                      return <Alert severity="error">{error.message}</Alert>;
                    })}
                    {msgs.map(function (msg) {
                      return <Alert severity="info">{msg}</Alert>;
                    })}
                  </div>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Box>
    </div>
  );
}
