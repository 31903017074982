import React from "react";
import Box from "@mui/material/Box";
import { PrimaryButton } from "../components/buttons";
import { useNavigate } from "react-router-dom";
export default function Recovered() {
  let darkMode = true;
  let navigate = useNavigate();
  return (
    <div>
      <Box
        container
        style={{
          height: "100vh",
          overflow: "hidden",
          width: "100vw",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <div>Yopur password has been changed you can now login.</div>
        <div
          style={{
            width: "100%",
            height: "100px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <div style={{ width: "300px", height: "100px" }}>
            <PrimaryButton
              darkMode={darkMode}
              onSubmit={() => {
                navigate("/login");
              }}
              text={"LOGIN"}
            ></PrimaryButton>
          </div>
        </div>
      </Box>
    </div>
  );
}
