export const config = {
  VITE_APP_API_KEY: "AIzaSyDRqCDcIaIXpeiZMeCEc2bO_6n-JB293TQ",
  VITE_APP_AUTH_DOMAIN: "novomo-f5610.firebaseapp.com",
  VITE_APP_PROJECT_ID: "novomo-f5610",
  VITE_APP_STORAGE_BUCKET: "novomo-f5610.appspot.com",
  VITE_APP_MESSAGING_SENDER_ID: "952510103465",
  VITE_APP_APP_ID: "1:952510103465:web:6e5034dae3a711311cceb8",
  VITE_APP_MEASUREMENT_ID: "G-2PW54G2YKL",
  VITE_APP_VAPID_KEY:
    "BEp0tRJAbWv0cfuNEk2DcX47YeXa7FFYkxL28JU7D7mnsyQ954oO-sva98_jf3o62XBzPvRw0gGgD6-lx2Pae2s",
};
