import React, { useContext, useEffect } from "react";
import Card from "@material-ui/core/Card";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import SaveIcon from "@material-ui/icons/Save";
import AddList from "./AddList";
import Widgets from "../widgets";
import { AuthContext } from "../context/authContext";
import { useMutation } from "@apollo/client";
import { gql } from "graphql-tag";
import { useNavigate } from "react-router-dom";
const SAVE_LAYOUT = gql`
  mutation SaveLayout($inputLayout: InputLayout!) {
    saveLayout(inputLayout: $inputLayout)
  }
`;
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
  },
}));
export default function TopBar({ ...props }) {
  let navigate = useNavigate();

  const { user, layout, dashKey } = useContext(AuthContext);
  console.log(user);
  console.log(dashKey);

  useEffect(() => {
    console.log(user);
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);
  const [saveLayout] = useMutation(SAVE_LAYOUT, {
    variables: {
      inputLayout: {
        _id:
          !user || !user.layouts || user.layouts.length === 0
            ? ""
            : user.layouts[dashKey]._id,
        label:
          !user || !user.layouts || user.layouts.length === 0
            ? ""
            : user.layouts[dashKey].label,
        layout: layout,
      },
    },
  });

  const onLayoutSave = () => {
    //save to database
    //saveToLS("layouts", layouts);
    saveLayout();
  };
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <AddList />
      <IconButton aria-label="save" onClick={onLayoutSave}>
        <SaveIcon />
      </IconButton>
    </Card>
  );
}
