import React from "react";
import Menu from "@mui/material/Menu";
import NotificationMenuItem from "./NotificationMenuItem";
import Box from "@mui/material/Box";
import { colours as darkColours } from "../themes/dark/dark";
import { colours as lightColours } from "../themes/light/light";
import zIndex from "@material-ui/core/styles/zIndex";
const BasicMenu = ({ anchorEl, handleClose, open, menuItems, darkMode }) => {
  return (
    <Box
      id="basic-menu"
      sx={{
        zIndex: 500,
        position: "absolute",
        maxHeight: "80vh",
        top: "70px",
        right: "0px",
        width: "350px",
        padding: "10px 5px",
        display: open ? "block" : "none",
        flexDirection: "column",
        overflowY: "auto",
        overflowX: "hidden",
        alignItems: "center",
        backgroundColor: darkMode
          ? darkColours.primaryTextColour
          : lightColours.primaryTextColour,
      }}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
    >
      {menuItems.map((item) => (
        <NotificationMenuItem notification={item} darkMode={darkMode}>
          {item.label}
        </NotificationMenuItem>
      ))}
    </Box>
  );
};

export default BasicMenu;
