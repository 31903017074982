import { useEffect, useState } from "react";
import { gql } from "graphql-tag";
import { useForm } from "../utility/hooks";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { createContext } from "react";
import Link from "@mui/material/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@mui/material/Typography";
import veritasLogo from "../components/assets/images/veritas-logo.png";
import { TextField, Alert } from "@mui/material";
import { PrimaryButton } from "../components/buttons";
import { lightTitle } from "../themes/light/title";
import { darkTitle } from "../themes/dark/title";
import { lightTextInput } from "../themes/light/inputs";
import { darkTextInput } from "../themes/dark/inputs";
import { useMutation } from "@apollo/client";
import OTPInput from "../components/OTPInput";
import Recovered from "../components/Recovered";
import Reset from "../components/Reset";
const SEND_EMAIL = gql`
  mutation ForgottenPassword($email: String!, $OTP: String!) {
    forgottenPassword(email: $email, OTP: $OTP)
  }
`;
export const RecoveryContext = createContext();

function ResetPass({ site, open, ...props }) {
  const [page, setPage] = useState("forgot");
  const [otp, setOtp] = useState("");
  const [errors, setErrors] = useState([]);
  const [msgs, setMsgs] = useState([]);
  let darkMode = true;
  const { onChange, onSubmit, values } = useForm(submitCallbacks, {
    email: "",
  });

  let navigate = useNavigate();
  const [forgotPass] = useMutation(SEND_EMAIL, {
    update(proxy, { data: { forgottenPassword } }) {
      setPage("otp");
    },
    onError({ graphQLErrors }) {
      setErrors(graphQLErrors);
    },
    variables: {
      email: values.email,
      OTP: otp,
    },
  });
  function submitCallbacks() {
    if (page === "forgot") {
      console.log(otp);
      console.log(values);
      forgotPass();
    }
  }

  useEffect(() => {
    console.log(otp);
    if (otp === "") {
      setOtp(Math.floor(Math.random() * 9000 + 1000).toString());
    }
  }, [setOtp, otp]);
  if (page === "otp")
    return (
      <RecoveryContext.Provider
        value={{ page, setPage, otp, setOtp, email: values.email }}
      >
        <OTPInput darkMode={darkMode} site={site} email={values.email} />
      </RecoveryContext.Provider>
    );
  if (page === "reset")
    return (
      <RecoveryContext.Provider
        value={{ page, setPage, otp, setOtp, email: values.email }}
      >
        <Reset darkMode={darkMode} site={site} email={values.email} />
      </RecoveryContext.Provider>
    );
  if (page === "recovered ")
    return (
      <RecoveryContext.Provider
        value={{ page, setPage, otp, setOtp, email: values.email }}
      >
        <Recovered />
      </RecoveryContext.Provider>
    );
  return (
    <div>
      <Box
        container
        style={{
          height: "100vh",
          overflow: "hidden",
          width: "100vw",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Grid item xs={12} sm={12} md={6} lg={6} fullWidth>
          <Box
            sx={{
              height: "100vh",
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              backgroundImage: { xs: "none" },
            }}
          >
            <Box
              sx={{
                height: "25%",
                mx: 4,
                gap: 2,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Link href="/">
                {site.includes("novomo") && (
                  <img
                    alt="veritas-logo"
                    src={veritasLogo}
                    width={100}
                    height={100}
                  />
                )}
                {site.includes("localhost") && (
                  <img
                    alt="veritas-logo"
                    src={veritasLogo}
                    width={100}
                    height={100}
                  />
                )}
              </Link>
              <Typography
                component="h1"
                variant="h3"
                marginTop={7}
                style={darkMode ? darkTitle : lightTitle}
              >
                Veritas
              </Typography>
            </Box>
            <Box
              sx={{
                height: "75%",
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography component="h1" variant="h5">
                Forgotton Password!{" "}
              </Typography>
              <Box
                component="form"
                width={"400px"}
                noValidate
                sx={{ mt: 1 }}
                height={"80%"}
              >
                <TextField
                  style={darkMode ? darkTextInput : lightTextInput}
                  margin="normal"
                  required
                  fullWidth
                  label="email"
                  id="email"
                  name="email"
                  onChange={onChange}
                  autoComplete="email"
                  autoFocus
                />

                {errors.map(function (error) {
                  return <Alert severity="error">{error.message}</Alert>;
                })}
                {msgs.map(function (msg) {
                  return <Alert severity="info">{msg}</Alert>;
                })}
                <PrimaryButton
                  darkMode={darkMode}
                  onSubmit={onSubmit}
                  text={"Send Email"}
                ></PrimaryButton>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Box>
    </div>
  );
}

export default ResetPass;
