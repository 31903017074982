import React from "react";
import AlertTitle from "@mui/material/AlertTitle";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Alert, drawerClasses } from "@mui/material";
import Box from "@mui/material/Box";
import ActionFunctions from "../utility/actionFunctions";
import { colours as darkColours } from "../themes/dark/dark";
import { colours as lightColours } from "../themes/light/light";

const NotificationMenuItem = ({ darkMode, notification }) => {
  const { severity, link, actions, label, txt } = notification;
  console.log(darkMode);
  return (
    <Box
      sx={{
        height: "125px",
        width: "100%",
        mx: 1,
        paddingBottom: "6px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        border: `solid 1px ${darkColours.secondaryColour}`,
        margin: "4px",
        borderRadius: "5px",
      }}
    >
      <Box
        sx={{
          width: "250px",
          mx: 1,
          gap: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Alert severity={severity} sx={{ width: "100%" }}>
          <AlertTitle>{label}</AlertTitle>
        </Alert>
        <Typography
          component="p"
          sx={{ width: "100%", color: "black", textAlign: "center" }}
        >
          {txt}
        </Typography>
        {link && <Link href={link}></Link>}
      </Box>
      <Box
        sx={{
          mx: 1,
          gap: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "start",
          height: "100%",
        }}
      >
        {actions &&
          actions.length > 0 &&
          actions.map((action) => {
            return (
              <ActionFunctions
                action={action}
                notification={notification}
                darkMode={darkMode}
              />
            );
          })}
      </Box>
    </Box>
  );
};

export default NotificationMenuItem;
