import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import { gql } from "graphql-tag";
import { useMutation } from "@apollo/client";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "./buttons";
const VERIFY_TOKEN = gql`
  mutation VerifyEmailToken($username: String!, $token: String!) {
    verifyEmailToken(username: $username, token: $token)
  }
`;

export default function EmailVerification(props) {
  let navigate = useNavigate();
  let { username, token } = useParams();
  const [isValidToken, setIsValidToken] = useState(false);
  const { darkMode } = useContext(AuthContext);
  const [verifyToken] = useMutation(VERIFY_TOKEN, {
    update(proxy, { data: { verifyEmailToken } }) {
      console.log(verifyEmailToken);
      if (verifyEmailToken) {
        setIsValidToken(true);
      }
    },
    variables: {
      username,
      token,
    },
  });
  const verifyEmailToken = () => {
    console.log("testing token function");
    verifyToken();
  };

  useEffect(() => {
    verifyEmailToken();
  }, []);

  return (
    <div>
      {isValidToken ? (
        <Box
          container
          style={{
            height: "100vh",
            overflow: "hidden",
            width: "100vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <div>Email has been verified you can now login.</div>
          <div
            style={{
              width: "100%",
              height: "100px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <div style={{ width: "300px", height: "100px" }}>
              <PrimaryButton
                darkMode={darkMode}
                onSubmit={() => {
                  navigate("/login");
                }}
                text={"LOGIN"}
              ></PrimaryButton>
            </div>
          </div>
        </Box>
      ) : (
        <>
          <Box
            container
            style={{
              height: "100vh",
              overflow: "hidden",
              width: "100vw",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            Email has not been verified please check your email.
          </Box>
        </>
      )}
    </div>
  );
}
