import { jwtDecode } from "jwt-decode";
import { v4 as uuidv4 } from "uuid";
import { createContext, useReducer } from "react";
const initialState = {
  user: null,
  layout: [],
  gridItems: [],
  dashKey: 0,
};
console.log(localStorage.getItem("token"));
if (localStorage.getItem("token")) {
  initialState.user = JSON.parse(localStorage.getItem("token"));
}

if (localStorage.getItem("layout")) {
  const layoutDets = JSON.parse(localStorage.getItem("layout"));

  initialState.layout = layoutDets.layout;
  initialState.gridItems = layoutDets.gridItems;
  initialState.dashKey = layoutDets.dashKey;
}

const AuthContext = createContext({
  user: null,
  login: (userData) => {},
  logout: () => {},
  addDash: () => {},
  editDash: () => {},
  changeDash: () => {},
  onRemoveWidget: () => {},
  addWidget: () => {},
  setLayout: () => {},
});

function authReducer(state, action) {
  console.log(action);
  switch (action.type) {
    case "NEW_STATE":
      return {
        ...action.payload,
      };
    case "LOGIN":
      return {
        ...state,
        user: action.payload,
      };
    case "LOGOUT":
      return {
        ...state,
        user: null,
      };
    case "CHANGE_DASH":
      return {
        ...state,
        dashKey: action.payload.key,
        layout: state.user.layouts[action.payload.key].layout,
        gridItems: state.user.layouts[action.payload.key].layout,
      };
    case "ADD_DASH":
      return {
        ...state,
        dashKey: state.user.layouts.length,
        layout: [],
        gridItems: [],
        user: {
          ...state.user,
          layouts: [
            ...state.user.layouts,
            { label: `Dashboard ${state.user.layouts.length}`, layout: [] },
          ],
        },
      };
    case "EDIT_DASH":
      state.user.layouts[action.payload.index] = {
        ...state.user.layouts[action.payload.index],
        ...action.payload.layout,
      };
      console.log(state);
      return {
        ...state,
      };
    case "REMOVE_WIDGET":
      state.user.layouts[state.dashKey].layout = state.layout.filter(
        (i) => i !== action.payload.itemId
      );
      return {
        ...state,
        layout: state.layout.filter((i) => i.i !== action.payload.itemId),
        gridItems: state.gridItems.filter((i) => i.i !== action.payload.itemId),
      };
    case "CHANGE_LAYOUT":
      return {
        ...state,
        layout: action.payload.layout,
        gridItems: action.payload.gridItems,
      };
    default:
      return state;
  }
}

function AuthProvider(props) {
  const [state, dispatch] = useReducer(authReducer, initialState);

  const setLocal = (newState) => {
    console.log(newState);
    console.log(1);

    console.log(2);
    localStorage.setItem("token", JSON.stringify({ ...newState.user }));
    console.log(3);
    localStorage.setItem(
      "layout",
      JSON.stringify({
        layout: newState.layout,
        dashKey: newState.dashKey,
        gridItems: newState.gridItems,
      })
    );
    console.log(4);
  };
  const saveState = (newState) => {
    setLocal(newState);
    dispatch({
      type: "NEW_STATE",
      payload: newState,
    });
  };
  const login = (userData) => {
    try {
      const decodedToken = jwtDecode(userData.token);
      console.log({
        ...state,
        user: decodedToken,
        layout:
          !decodedToken.layouts[0] || !decodedToken.layouts[0].layout
            ? []
            : decodedToken.layouts[0].layout,
        dashKey: 0,
        gridItems:
          !decodedToken.layouts[0] || !decodedToken.layouts[0].layout
            ? []
            : decodedToken.layouts[0].layout,
      });
      saveState({
        ...state,
        user: decodedToken,
        layout:
          !decodedToken.layouts[0] || !decodedToken.layouts[0].layout
            ? []
            : decodedToken.layouts[0].layout,
        dashKey: 0,
        gridItems:
          !decodedToken.layouts[0] || !decodedToken.layouts[0].layout
            ? []
            : decodedToken.layouts[0].layout,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("layout");
    dispatch({ type: "LOGOUT" });
  };

  const addDash = () => {
    console.log("add dash");

    saveState({
      layout: [],
      dashKey: state.user.layouts.length,
      gridItems: [],
      user: {
        ...state.user,
        layouts: [
          ...state.user.layouts,
          {
            _id: uuidv4().toString(),
            label: `Dashboard ${state.user.layouts.length}`,
            layout: [],
          },
        ],
      },
    });
  };

  const editDash = (index, layout) => {
    let layouts = [...state.user.layouts];
    layouts[index] = { ...layouts[index], ...layout };

    saveState({
      ...state,
      user: {
        ...state.user,
        layouts: layouts,
      },
    });
  };

  const changeDash = (key) => {
    saveState({
      ...state,
      dashKey: key,
      layout: state.user.layouts[key].layout,
      gridItems: state.user.layouts[key].layout,
    });
  };

  const onRemoveWidget = (itemId) => {
    saveState({
      ...state,
      layout: state.layout.filter((i) => i.i !== itemId),
      gridItems: state.gridItems.filter((i) => i.i !== itemId),
      dashKey: state.dashKey,
    });
  };
  const addWidget = (widgetName) => {
    const newItem = {
      x: 0,
      y: Infinity, // Place at the bottom
      w: 2, // Width of the grid item
      h: 2, // Height of the grid item
      i: widgetName, // Unique key
    };

    saveState({
      ...state,
      layout: [...state.layout, newItem],
      gridItems: [...state.gridItems, newItem],
    });
  };

  const setLayout = (layout) => {
    saveState({ ...state, layout: layout, gridItems: layout });
  };

  return (
    <AuthContext.Provider
      value={{
        user: state.user,
        login,
        logout,
        addDash,
        editDash,
        changeDash,
        onRemoveWidget,
        addWidget,
        setLayout,

        layout: state.layout,
        gridItems: state.gridItems,
        dashKey: state.dashKey,
      }}
      {...props}
    />
  );
}

export { AuthContext, AuthProvider };
