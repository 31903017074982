import NotificationsIcon from "@material-ui/icons/Notifications";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/authContext";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import BasicMenu from "./BasicMenu";
import Badge from "@material-ui/core/Badge";
import { useQuery } from "@apollo/client";
import { gql } from "graphql-tag";
const NOTIFICATIONS_QUERY = gql`
  query {
    notifications {
      _id
      label
      txt
      tags
      createdAt
      readAt
      link
      actions
      severity
    }
  }
`;

const NOTIFICATION_SUBSCRIPTION = gql`
  subscription NewNotification($tags: [String!]!, $userId: String) {
    newNotification(tags: $tags, userId: $userId) {
      _id
      label
      txt
      tags
      createdAt
      readAt
      link
      actions
      severity
    }
  }
`;

function Notifications({ darkMode, ...props }) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const handleAlertClick = () => {
    setAlertOpen(true);
  };

  const handleAlertClose = (event, reason) => {
    if (alertOpen && reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const { subscribeToMore, data } = useQuery(NOTIFICATIONS_QUERY);

  useEffect(() => {
    subscribeToMore({
      document: NOTIFICATION_SUBSCRIPTION,
      variables: {
        tags: user.notificationSettings
          ? user.notificationSettings.tags
          : ["all"],
        userId: user._id,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newFeedItem = subscriptionData.data.newNotification;

        const oldNotifications = prev.notifications.filter(function (el) {
          return el._id === newFeedItem._id;
        });
        console.log(oldNotifications);
        console.log("e");

        if (oldNotifications.length > 0) {
          return Object.assign({}, prev, {
            notifications: prev.notifications.map((notification) => {
              if (notification._id === newFeedItem._id) {
                return newFeedItem; // Replace edition with a new value
              }
              return notification;
            }),
          });
        } else {
          return Object.assign({}, prev, {
            notifications: [newFeedItem, ...prev.notifications],
          });
        }
      },
    });
  });

  const handleToggle = (event) => {
    if (data && data.notifications.length === 0) {
      setOpen(false);
      return;
    }

    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = (event) => {
    setOpen(false);
  };
  console.log(data);

  const newNotifications = data
    ? `You have ${data.notifications.length} new notifications!`
    : ``;
  const newNotificationsLength = data
    ? data.notifications.filter((notification) => {
        return !notification.readAt || notification.readAt === null;
      }).length
    : 0;
  const noNotifications = "No new notifications";
  return (
    <div>
      <Tooltip
        title={
          data && newNotificationsLength > 0 // change to number unread
            ? newNotifications
            : noNotifications
        }
      >
        <IconButton onClick={handleToggle} anchorEl={anchorEl}>
          <Badge
            badgeContent={
              data && data.notifications.length > 0
                ? newNotificationsLength
                : null
            }
            color="error"
          >
            <NotificationsIcon style={{ color: "white" }} />
          </Badge>
        </IconButton>
      </Tooltip>
      {data && data.notifications.length > 0 && (
        <BasicMenu
          darkMode={darkMode}
          open={open}
          anchorEl={anchorEl}
          handleClose={handleClose}
          menuItems={data.notifications}
        />
      )}
    </div>
  );
}

export default Notifications;
