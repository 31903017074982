import React, { useCallback, useContext } from "react";
import { AuthContext } from "../context/authContext";
import { Responsive, WidthProvider } from "react-grid-layout";

import Widget from "../widgets/Widget";
import TopBar from "./TopBar";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

const ResponsiveGridLayout = WidthProvider(Responsive);

function MyGridLayout({ ...props }) {
  const { gridItems, layout, setLayout } = useContext(AuthContext);
  console.log(gridItems);

  const onLayoutChange = useCallback(
    (newLayout) => {
      setLayout(newLayout);
    },
    [setLayout]
  );

  return (
    <div>
      <TopBar />
      <ResponsiveGridLayout
        className="layout"
        layouts={{ lg: layout, md: layout }}
        onLayoutChange={onLayoutChange}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        margin={[10, 10]}
      >
        {gridItems &&
          gridItems.map((item) => (
            <div key={item.i} className="widget">
              <Widget id={item.i} backgroundColor="#867ae9" />
            </div>
          ))}
      </ResponsiveGridLayout>
    </div>
  );
}

export default MyGridLayout;
