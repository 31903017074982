import React from "react";
import AlertTitle from "@mui/material/AlertTitle";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import { Alert } from "@mui/material";
import Box from "@mui/material/Box";
import { colours as darkColours } from "../themes/dark/dark";

const BasicSnackbar = ({ notification }) => {
  console.log(notification);
  const { severity, link, label, txt } = notification;
  return (
    <Box
      sx={{
        height: "125px",
        width: "100%",
        mx: 1,
        paddingBottom: "6px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        border: `solid 1px ${darkColours.secondaryColour}`,
        margin: "4px",
        borderRadius: "5px",
      }}
    >
      <Box
        sx={{
          width: "250px",
          mx: 1,
          gap: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Alert severity={severity} sx={{ width: "100%" }}>
          <AlertTitle>{label}</AlertTitle>
        </Alert>
        <Typography
          component="p"
          sx={{ width: "100%", color: "black", textAlign: "center" }}
        >
          {txt}
        </Typography>
        {link && <Link href={link}></Link>}
      </Box>
    </Box>
  );
};

export default BasicSnackbar;
