// Import the functions you need from the SDKs you need
import { initializeApp } from "@firebase/app";
import { getMessaging, getToken, onMessage } from "@firebase/messaging";
import { config } from "../config";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
console.log(process.env);
const firebaseConfig = {
  apiKey: config.VITE_APP_API_KEY,
  authDomain: config.VITE_APP_AUTH_DOMAIN,
  projectId: config.VITE_APP_PROJECT_ID,
  storageBucket: config.VITE_APP_STORAGE_BUCKET,
  messagingSenderId: config.VITE_APP_MESSAGING_SENDER_ID,
  appId: config.VITE_APP_APP_ID,
  measurementId: config.VITE_APP_MEASUREMENT_ID,
};
const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
const setupNotifications = async () => {
  try {
    // Request permission for notifications
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      console.log("Notification permission granted.");
      // Get the FCM token
      const token = await getToken(messaging);
      console.log("FCM Token:", token);
    } else {
      console.log("Notification permission denied.");
    }
    // Handle foreground notifications
    onMessage(messaging, (payload) => {
      console.log("Foreground Message:", payload);
      // Handle the notification or update your UI
    });
  } catch (error) {
    console.error("Error setting up notifications:", error);
  }
};
export { messaging, setupNotifications };
