import { dark } from "@material-ui/core/styles/createPalette";
import { deepOrange, grey } from "@mui/material/colors";

const primaryColour = "#010216";
const secondaryColour = "#587B7F";
const primaryTextColour = "#fff";
const secondaryTextColour = "#363031";
const primaryHighlightsColour = "#82C262";
const secondaryHighlightsColour = "#47682C";

export const darkTheme = {
  // 👇 palette values for light mode
  primary: { main: primaryColour },
  divider: primaryHighlightsColour,
  background: {
    default: primaryColour,
  },
  text: {
    primary: primaryTextColour,
    secondary: secondaryTextColour,
  },
};

export const colours = {
  primaryColour,
  secondaryColour,
  primaryTextColour,
  secondaryTextColour,
  primaryHighlightsColour,
  secondaryHighlightsColour,
};
