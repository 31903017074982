import { ActionButton } from "../components/buttons";
import { useMutation } from "@apollo/client";
import React from "react";
import { gql } from "graphql-tag";
import MarkEmailUnread from "@mui/icons-material/MarkEmailUnread";
import { MarkEmailRead } from "@mui/icons-material";
const UPDATE_NOTIFICATION = gql`
  mutation EditNotification($inputNotification: InputNotification!) {
    editNotification(inputNotification: $inputNotification) {
      _id
    }
  }
`;

const ChangeRead = ({ notification, darkMode, ...props }) => {
  console.log(notification);
  console.log(notification.readAt);
  const [updateNotication] = useMutation(UPDATE_NOTIFICATION, {
    variables: {
      inputNotification: {
        _id: notification._id,
        userId: notification.userId ? notification.userId : "",
        readAt: notification.readAt
          ? notification.readAt
          : Math.floor(new Date().getTime() / 1000),
      },
    },
  });

  const onSubmit = () => {
    if (notification.readAt) {
      return;
    }
    updateNotication();
  };
  return (
    <ActionButton
      darkMode={darkMode}
      disabled={notification.readAt ? true : false}
      onSubmit={onSubmit}
      icon={notification.readAt ? "read" : "unread"}
    />
  );
};

export default function ActionFunctions({
  action,
  notification,
  darkMode,
  ...props
}) {
  console.log(action);
  if (action === "change-read") {
    return <ChangeRead notification={notification} darkMode={darkMode} />;
  }
}
