import React, { useState } from "react";
import { useContext } from "react";
import { RecoveryContext } from "../pages/resetPass";
import { Box } from "@mui/material";
import Grid from "@material-ui/core/Grid";
import Link from "@mui/material/Link";
import veritasLogo from "../components/assets/images/veritas-logo.png";
import { useForm } from "../utility/hooks";
import Typography from "@mui/material/Typography";
import { TextField, Alert } from "@mui/material";
import { PrimaryButton } from "../components/buttons";
import { lightTitle } from "../themes/light/title";
import { darkTitle } from "../themes/dark/title";
import { lightTextInput } from "../themes/light/inputs";
import { darkTextInput } from "../themes/dark/inputs";
import { useMutation } from "@apollo/client";
import { gql } from "graphql-tag";
import bcrypt from "bcryptjs";
const SEND_PASS = gql`
  mutation resetPassword($email: String!, $password: String!) {
    resetPassword(email: $email, password: $password)
  }
`;

export default function Reset({ site, darkMode, email, ...props }) {
  const [errors, setErrors] = useState([]);
  const [msgs, setMsgs] = useState([]);
  const { setPage } = useContext(RecoveryContext);

  const { onChange, onSubmit, values } = useForm(changePasswordCallback, {
    password: "",
    confirmPassword: "",
  });

  const [changePass] = useMutation(SEND_PASS, {
    update(proxy, { data: { resetPassword } }) {
      setPage("login");
    },
    onError({ graphQLErrors }) {
      setErrors(graphQLErrors);
    },
    variables: {
      email: email,
      password: bcrypt.hashSync(values.password, 10),
    },
  });
  function changePasswordCallback() {
    console.log(values);
    if (values.password !== values.confirmPassword) {
      setErrors(["Passwords do not match!"]);
      return;
    }
    changePass();
    setPage("recovered");
  }

  return (
    <div>
      <Box
        container
        style={{
          height: "100vh",
          overflow: "hidden",
          width: "100vw",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Box
            sx={{
              height: "100vh",
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              backgroundImage: { xs: "none" },
            }}
          >
            <Box
              sx={{
                height: "25%",
                mx: 4,
                gap: 2,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Link href="/">
                {site.includes("novomo") && (
                  <img
                    alt="veritas-logo"
                    src={veritasLogo}
                    width={100}
                    height={100}
                  />
                )}
                {site.includes("localhost") && (
                  <img
                    alt="veritas-logo"
                    src={veritasLogo}
                    width={100}
                    height={100}
                  />
                )}
              </Link>
              <Typography
                component="h1"
                variant="h3"
                marginTop={7}
                style={darkMode ? darkTitle : lightTitle}
              >
                Veritas
              </Typography>
            </Box>
            <Box
              sx={{
                height: "75%",
                mx: 4,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography component="h1" variant="h5">
                Enter New Password!{" "}
              </Typography>
              <Box
                component="form"
                width={"400px"}
                noValidate
                sx={{ mt: 1 }}
                height={"80%"}
              >
                <TextField
                  style={darkMode ? darkTextInput : lightTextInput}
                  margin="normal"
                  required
                  fullWidth
                  label="password"
                  name="password"
                  type="password"
                  onChange={onChange}
                  id="password"
                  autoComplete="current-password"
                />

                <TextField
                  style={darkMode ? darkTextInput : lightTextInput}
                  margin="normal"
                  required
                  fullWidth
                  label="confirmPassword"
                  name="confirmPassword"
                  type="password"
                  onChange={onChange}
                  id="confirmPassword"
                  autoComplete="current-password"
                />

                {errors.map(function (error) {
                  return <Alert severity="error">{error.message}</Alert>;
                })}
                {msgs.map(function (msg) {
                  return <Alert severity="info">{msg}</Alert>;
                })}
                <PrimaryButton
                  darkMode={darkMode}
                  onSubmit={onSubmit}
                  text={"Change Password"}
                ></PrimaryButton>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Box>
    </div>
  );
}
