import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import Notifications from "./Notifications";
import React from "react";
import Link from "@mui/material/Link";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MenuIcon from "@material-ui/icons/Menu";
import IconButton from "@material-ui/core/IconButton";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import Brightness7Icon from "@material-ui/icons/Brightness7";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { makeStyles } from "@material-ui/core/styles";
import veritasLogo from "./assets/images/veritas-logo.png";
const useStyles = makeStyles((theme) => ({
  appbar: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    [theme.breakpoints.up("sm")]: {
      zIndex: theme.zIndex.drawer + 1,
    },
  },
  rightIcons: {
    marginLeft: theme.spacing(0.5),
  },
  spacer: {
    flexGrow: 1,
  },
}));

function Navbar({ handleDrawerToggle, toggleDarkMode, darkMode, site }) {
  const { user, logout } = useContext(AuthContext);

  const classes = useStyles();
  const page_url = window.location.href;
  let navigate = useNavigate();
  const onLogout = () => {
    logout();
    navigate("/");
  };
  useEffect(() => {
    console.log(page_url);
    if (page_url.includes("login") && user) {
      navigate("/");
    }
  }, [user, navigate, page_url]);
  console.log(user);
  return (
    <AppBar position="fixed" className={classes.appbar}>
      <Toolbar>
        {user && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
          >
            <MenuIcon />
          </IconButton>
        )}
        <Typography variant="h6" noWrap>
          {site.includes("novomo") && "Veritas"}
          {site.includes("localhost") && "Veritas"}
        </Typography>
        <Link href="/">
          {site.includes("novomo") && (
            <img alt="veritas-logo" src={veritasLogo} width={80} height={80} />
          )}
          {site.includes("localhost") && (
            <img alt="veritas-logo" src={veritasLogo} width={80} height={80} />
          )}
        </Link>
        <div className={classes.spacer} />
        {user ? (
          <>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDarkMode}
              edge="start"
              className={classes.rightIcons}
            >
              {darkMode ? <Brightness7Icon /> : <Brightness4Icon />}
            </IconButton>

            <Notifications darkMode={darkMode} />
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              className={classes.rightIcons}
            >
              <AccountCircleIcon />
            </IconButton>
          </>
        ) : (
          <></>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
