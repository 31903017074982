const primaryColour = "#C9CAD9";
const secondaryColour = "#C3D0B9";
const primaryTextColour = "#00000";
const secondaryTextColour = "#587B7F";
const primaryHighlightsColour = "#47682C";
const secondaryHighlightsColour = "#82C262";

export const lightTheme = {
  // 👇 palette values for light mode
  primary: { main: primaryColour },
  divider: primaryHighlightsColour,
  background: {
    default: primaryColour,
  },
  text: {
    primary: primaryTextColour,
    secondary: secondaryTextColour,
  },
};

export const colours = {
  primaryColour,
  secondaryColour,
  primaryTextColour,
  secondaryTextColour,
  primaryHighlightsColour,
  secondaryHighlightsColour,
};
