import React, { useContext } from "react";
import { AuthContext } from "../context/authContext";
import { makeStyles } from "@material-ui/core/styles";
import Popover from "@material-ui/core/Popover";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Widgets from "../widgets";

const useStyles = makeStyles((theme) => ({
  popup: {
    padding: theme.spacing(2),
  },
}));

const widgetNames = {
  a: "A",
  b: "B",
  c: "C",
  d: "D",
};

export default function AddList({ ...props }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { addWidget, onRemoveWidget, layout } = useContext(AuthContext);
  const widgetKeys = Object.keys(Widgets);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  console.log(layout);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleChange = (e) => {
    console.log(e.target.checked);
    if (e.target.checked) {
      addWidget(e.target.name);
    } else {
      onRemoveWidget(e.target.name);
    }
  };

  return (
    <>
      <IconButton aria-label="add" onClick={handleClick} aria-describedby={id}>
        <AddCircleOutlineIcon />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className={classes.popup}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Select Widgets</FormLabel>
            <FormGroup>
              {widgetKeys.map((widgetName) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        layout &&
                        layout.findIndex((widget) => {
                          return widget.i === widgetName;
                        }) !== -1
                          ? true
                          : false
                      }
                      onChange={handleChange}
                      name={widgetName}
                    />
                  }
                  label={widgetName}
                  key={widgetName}
                  style={{ color: "black" }}
                />
              ))}
            </FormGroup>
          </FormControl>
        </div>
      </Popover>
    </>
  );
}
