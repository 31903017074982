import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";

let token = "";
if (localStorage.getItem("token")) {
  token = JSON.parse(localStorage.getItem("token")).token;
}
const wsLink = new GraphQLWsLink(
  createClient({
    url: "wss://www.akashic-record.co.uk/subscriptions",
    connectionParams: {
      authToken: `luce-token:${
        localStorage.getItem("token")
          ? JSON.parse(localStorage.getItem("token")).token
          : ""
      }`,
    },
  })
);

const httpLink = createHttpLink({
  uri: "https://www.akashic-record.co.uk/graphql",
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: `luce-token:${
        localStorage.getItem("token")
          ? JSON.parse(localStorage.getItem("token")).token
          : ""
      }`,
    },
  };
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  authLink.concat(httpLink)
);

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
});

export default client;
