import { colours } from "./dark";
export const darkTextInput = {
  backgroundColor: colours.primaryTextColour,
  opacity: 0.8,
};

export const darkMenuInput = {
  backgroundColor: colours.primaryTextColour,
  opacity: 0.8,
  "&:focus": {
    backgroundColor: colours.secondaryTextColour,
    borderBottom: "solid 1px",
  },
};
